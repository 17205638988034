import React from "react";
import { graphql, Link } from "gatsby";

// Component imports
import Layout from "../components/Layout";
import BackgroundImage from "gatsby-background-image";
import { motion } from "framer-motion";
import SEO from "../components/SEO";

import moment from "moment/moment";

import Section from "../components/Section";

import { InView } from "react-intersection-observer";
import FeaturedPost from "../components/FeaturedPost";
import Text from "../components/Text";

import useWindowSize from "../components/hooks/useWindowSize";
import { PopupButton } from "@typeform/embed-react";

const Job = ({ data }: any) => {
    console.log(data);
    const job = data.ghostPost;

    const EmbedComponent = () => {
        return (
            <PopupButton
                id="tf9oA5Yz"
                style={{ fontSize: 20 }}
                className="block py-4 w-full max-w-xs m-auto mt-10 text-sm font-semibold text-center uppercase transition-all duration-300 border-2 border-gray-500 ease-easeInOutQuint hover:bg-white hover:text-black hover:border-transparent focus:bg-white focus:font-semibold focus:border-transparent focus:outline-none active:outline-none"
            >
                Apply
            </PopupButton>
        );
    };
    return (
        <Layout>
            <SEO
                title={job.title}
                article={true}
                description={job.excerpt}
                articleDatetime={job.created_at}
                articleModifiedTime={job.updated_at}
            />
            <article>
                <Section>
                    <h1 className="text-3xl font-semibold">{job.title}</h1>
                    <div
                        dangerouslySetInnerHTML={{ __html: job.html }}
                        className="pt-postContent !pt-18 "
                    ></div>
                    <EmbedComponent />
                </Section>
            </article>
        </Layout>
    );
};
export default Job;
export const jobQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            title
            slug
            feature_image
            authors {
                name
            }
            created_at
            updated_at
            excerpt

            html
        }
    }
`;
